import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import {
  createInitialState,
  FormState,
} from '../../utils/state/initialStateFactory';
import { createControlledComponent } from '../../utils/ControlledComponent/ControlledComponent';
import {
  createFormContext,
  FormContext,
} from '../../utils/context/contextFactory';
import { createFormActions, FormControllerActions } from './Actions/actions';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { FormApi } from '../../api/FormApi';

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  let rerender: () => void = () => {};
  return {
    async pageReady() {
      const {
        controllerConfig,
        translations: { t },
        settings,
      } = flowAPI;

      const wixSdkAdapter = createWixSdkAdapter(controllerConfig);
      const formApi = new FormApi({ wixSdkAdapter });

      const initialState: FormState = await createInitialState({
        wixSdkAdapter,
        flowApi: flowAPI,
        formApi,
        t,
      });
      const formContext: FormContext = createFormContext({
        t,
        settings,
        wixSdkAdapter,
        formApi,
      });

      const { render } = await createControlledComponent<
        FormState,
        FormControllerActions,
        FormContext
      >({
        controllerConfig,
        initialState,
        actionsFactory: createFormActions,
        context: formContext,
      });

      rerender = render;
    },
    updateConfig() {
      rerender();
    },
  };
};

export default createController;
