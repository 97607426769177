import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';

export type OnTogglePromoCode = () => void;

export function createOnTogglePromoCodeAction(
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): OnTogglePromoCode {
  return async () => {
    const [state, setState] = actionFactoryParams.getControllerState();

    setState({
      showPromoCodeInput: !state.showPromoCodeInput,
    });
  };
}
