import { BusinessInfoBase } from '@wix/bookings-uou-types';

export enum TabsTranslationsKeys {
  Manage = 'app.settings.tabs.manage',
  Layout = 'app.settings.tabs.layout',
  Display = 'app.settings.tabs.display',
  Design = 'app.settings.tabs.design',
  Text = 'app.settings.tabs.text',
}

export enum TabsDataHooks {
  Manage = 'manage-tab-button',
  Layout = 'layout-tab-button',
  Display = 'display-tab-button',
  Design = 'design-tab-button',
  Text = 'text-tab-button',
  Support = 'support-tab-button',
  Premium = 'premium-tab-button',
}

export enum SettingsTab {
  Manage = 'manage',
  Layout = 'layout',
  Display = 'display',
  Design = 'design',
  Text = 'text',
  Support = 'support',
}

export enum DesignTabSubSettings {
  STYLES = 'styles',
  TEXT = 'text',
  BUTTON = 'button',
}

export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum BorderStyle {
  UNDERLINE = 'line',
  BOX = 'box',
}

export enum PaymentMethod {
  MEMBERSHIP = 'membership',
  SINGLE = 'single',
}

export enum PaymentVariationType {
  PP = 'PP',
  BuyPP = 'BUY_PP',
  Custom = 'CUSTOM',
  Fixed = 'FIXED',
}

export enum ReservedPaymentOptionIds {
  SingleSession = 'SINGLE_SESSION_ID',
  BuyAPricingPlan = 'BUY_A_PRICING_PLAN_ID',
}

export enum CouponsErrorType {
  ERROR_COUPON_HAS_EXPIRED = 'ERROR_COUPON_HAS_EXPIRED',
  ERROR_COUPON_USAGE_EXCEEDED = 'ERROR_COUPON_USAGE_EXCEEDED',
  ERROR_COUPON_SERVICE_UNAVAILABLE = 'ERROR_COUPON_SERVICE_UNAVAILABLE',
  ERROR_COUPON_DOES_NOT_EXIST = 'ERROR_COUPON_DOES_NOT_EXIST',
}

export enum GeneralErrorType {
  INVALID_SLOT_AVAILABILITY = 'invalid_slot_availability',
  INVALID_SERVICE_ID = 'invalid_service_id',
  INVALID_CATALOG_DATA = 'invalid_catalog_data',
}

export interface BusinessInfo extends BusinessInfoBase {
  isSMSReminderEnabled: boolean;
}
