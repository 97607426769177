import {
  FormView,
  FormViewField,
  MultiLineAddressOptions,
  Submission,
  SubmissionResponse,
  SubmissionValue,
} from '@wix/forms-ui/types';
import {
  ContactDetails,
  FormInfo,
} from '@wix/ambassador-bookings-server/types';
import { Service } from './service.mapper';
import { BookingsKeyMapping } from './service-form-field.mapper';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { BusinessInfo } from '../../types/types';
import { isFixedPrice } from '../payment/payment';

export type OnSubmit = (submission: SubmissionResponse) => void;

export enum BookingRequestKeyMappings {
  ADDRESS = 'address',
  CONTACT_ID = 'contactId',
  COUNTRY_CODE = 'countryCode',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  FULL_ADDRESS = 'fullAddress',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  TIMEZONE = 'timeZone',
  NO_OF_PARTICIPANTS = 'noOfParticipants',
  SMS_REMINDER = 'smsReminder',
}

function getFieldIdByBookingKeyMap(formSchema: FormView): {
  [key: string]: string | undefined;
} {
  return (
    formSchema?.fields?.reduce<{
      [key: string]: string | undefined;
    }>((keyMap, field) => {
      const bookingsKeyMapping: BookingRequestKeyMappings =
        field?.renderInfo?.metadata?.bookingsKeyMapping?.key;
      const id = field.externalId;
      return {
        ...keyMap,
        [bookingsKeyMapping]: id,
      };
    }, {}) ?? {}
  );
}

function getMemberDetailsByBookingKeyMap(member: Member): {
  [key: string]: string | undefined;
} {
  return {
    [BookingRequestKeyMappings.EMAIL]: member.contact?.emails?.[0],
    [BookingRequestKeyMappings.PHONE]: member.contact?.phones?.[0],
    [BookingRequestKeyMappings.FIRST_NAME]: member.contact?.firstName,
    [BookingRequestKeyMappings.LAST_NAME]: member.contact?.lastName,
  };
}

export function mapMemberDetailsToSubmission(
  bookingKeys: BookingRequestKeyMappings[],
  formSchema: FormView,
  member: Member,
) {
  const bookingsKeyToFieldId = getFieldIdByBookingKeyMap(formSchema);
  const bookingsKeyToMemberDetails = getMemberDetailsByBookingKeyMap(member);
  return bookingKeys.reduce((submission: Submission, bookingKey) => {
    const fieldId = bookingsKeyToFieldId[bookingKey];
    const memberDetails = bookingsKeyToMemberDetails[bookingKey];
    const shouldApplyMemberDetails = fieldId && memberDetails;
    return {
      ...submission,
      ...(shouldApplyMemberDetails ? { [fieldId!]: memberDetails } : {}),
    };
  }, {});
}

function getSubmissionValue(
  submission: {
    [k: string]: SubmissionValue;
  },
  field: FormViewField,
) {
  if (
    typeof submission[field.externalId!] === 'boolean' &&
    !isSmsReminderField(field)
  ) {
    return submission[field.externalId!]!.toString();
  }
  return submission[field.externalId!];
}

export function mapSubmissionToPartialBookRequest(
  submission: {
    [k: string]: SubmissionValue;
  },
  service: Service,
  businessInfo: BusinessInfo,
): { formInfo: FormInfo; sendSmsReminder: boolean } {
  const { formSchema } = service;
  const formInfo: FormInfo = createBaseFormInfo(service);
  let sendSmsReminder = false;
  formSchema!.fields!.forEach((field) => {
    const value = getSubmissionValue(submission, field);
    if (value) {
      if (isNoOfParticipants(field)) {
        setNoOfParticipants(formInfo, Number(value));
      } else if (isSmsReminderField(field)) {
        sendSmsReminder = Boolean(value);
      } else if (isContactInfoField(field)) {
        appendContactInfoField(formInfo, businessInfo, field, value);
      } else {
        appendCustomField(formInfo, field, value);
      }
    }
  });
  return { formInfo, sendSmsReminder };
}

export enum RateLabels {
  GENERAL = 'general',
}

function createBaseFormInfo(service: Service): FormInfo {
  return {
    customFormFields: {},
    contactDetails: {},
    paymentSelection: [
      {
        numberOfParticipants: 1,
        ...(isFixedPrice(service.payment) ? { rateLabel: RateLabels.GENERAL } : {}),
      },
    ],
  };
}

function isContactInfoField(field: FormViewField) {
  return !!field?.renderInfo?.metadata?.bookingsKeyMapping;
}

function appendContactInfoField(
  formInfo: FormInfo,
  businessInfo: BusinessInfo,
  field: FormViewField,
  value: any,
) {
  const bookingsKeyMapping: BookingsKeyMapping =
    field?.renderInfo?.metadata?.bookingsKeyMapping;
  formInfo!.contactDetails![bookingsKeyMapping.key as keyof ContactDetails] =
    mapFormValueToContactInfoProperty?.[bookingsKeyMapping.key]?.(
      value,
      businessInfo,
    ) ?? value;
}

function isNoOfParticipants(field: FormViewField) {
  const bookingsKeyMapping: BookingsKeyMapping =
    field?.renderInfo?.metadata?.bookingsKeyMapping;
  return (
    bookingsKeyMapping?.key === BookingRequestKeyMappings.NO_OF_PARTICIPANTS
  );
}

function isSmsReminderField(field: FormViewField) {
  const bookingsKeyMapping: BookingsKeyMapping =
    field?.renderInfo?.metadata?.bookingsKeyMapping;
  return bookingsKeyMapping?.key === BookingRequestKeyMappings.SMS_REMINDER;
}

function setNoOfParticipants(formInfo: FormInfo, noOfParticipants: number) {
  formInfo!.paymentSelection!.find(
    (payment) => payment.numberOfParticipants,
  )!.numberOfParticipants = noOfParticipants;
}

function appendCustomField(
  formInfo: FormInfo,
  field: FormViewField,
  value: any,
) {
  formInfo!.customFormFields![field.externalId!] = value;
}

const filterUndefined = (value: any) => !!value;

export const mapFormValueToContactInfoProperty: {
  [key in BookingRequestKeyMappings]?: (
    value: any,
    businessInfo: BusinessInfo,
  ) => any;
} = {
  phone: (
    value: string | { countryCode: string; prefix: string; phone: string },
  ) => {
    if (typeof value === 'object') {
      const { prefix, phone } = value;
      return `${prefix}${phone}`;
    }
    return value;
  },
  fullAddress: (
    value: { [key in keyof MultiLineAddressOptions]: string },
    businessInfo,
  ) => {
    const { state, city, streetLine1, streetLine2 } = value;
    const isInJapanese = businessInfo.language === 'ja';
    const formattedAddress = isInJapanese
      ? `${[state, city, streetLine1].filter(filterUndefined).join('')}${
          streetLine2 ? ` ${streetLine2}` : ''
        }`
      : [streetLine1, streetLine2, city, state]
          .filter(filterUndefined)
          .join(', ');

    return {
      subdivision: state,
      city,
      addressLine: streetLine1,
      addressLine2: streetLine2,
      formattedAddress,
    };
  },
};
