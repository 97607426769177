import { FormState } from '../../../utils/state/initialStateFactory';
import { FormContext } from '../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import { createOnSubmitAction, OnSubmit } from './onSubmit/onSubmit';
import {
  createSetPaymentOptionAction,
  SetPaymentOption,
} from './setPaymentOption/setPaymentOption';
import { createOnLoginAction, OnLogin } from './onLogin/onLogin';
import { createSetCouponAction, SetCoupon } from './setCoupon/setCoupon';
import {
  createRemoveCouponAction,
  RemoveCoupon,
} from './removeCoupon/removeCoupon';
import {
  createOnTogglePromoCodeAction,
  OnTogglePromoCode,
} from './onTogglePromoCode/onTogglePromoCode';

export type FormControllerActions = {
  onSubmit: OnSubmit;
  setCoupon: SetCoupon;
  removeCoupon: RemoveCoupon;
  setPaymentOptionAction: SetPaymentOption;
  onLogin: OnLogin;
  onTogglePromoCode: OnTogglePromoCode;
};

export const createFormActions = (
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): FormControllerActions => ({
  onSubmit: createOnSubmitAction(actionFactoryParams),
  setPaymentOptionAction: createSetPaymentOptionAction(actionFactoryParams),
  onLogin: createOnLoginAction(actionFactoryParams),
  setCoupon: createSetCouponAction(actionFactoryParams),
  removeCoupon: createRemoveCouponAction(actionFactoryParams),
  onTogglePromoCode: createOnTogglePromoCodeAction(actionFactoryParams),
});
